import './form_steps.scss';

function form_steps(form_id, current_page){

    let container = document.getElementById('gform_'+ form_id);
    if( !container ) return;

    container.ff_form_steps_con = container.closest('.ff-form-steps');
    if( !container.ff_form_steps_con ) return;
    
    container.step_page = document.getElementById('gform_page_'+ form_id +'_'+ current_page);

    container.total_pages = container.querySelectorAll('.gform_page').length;
    
    container.step_page_footer = container.step_page.querySelector('.gform_page_footer');
    
    navigation_buttons(container, current_page);

    progress_bar(container, current_page);
}

function navigation_buttons(container, current_page){

    current_page = parseInt(current_page);
    
    // next button
    if( container.total_pages != current_page ) {
        // exclude next button on last page
        let next_button = document.createElement('div');
        next_button.classList.add('step_button');
        next_button.classList.add('next_button');
        next_button.addEventListener('click', ()=>{
            container.step_page_footer.querySelector('.gform_next_button').click();
        });
        container.step_page_footer.prepend(next_button);
    }
    
    // back button
    if( current_page > 1 && show_back_button(container) ) {
        // exclude back button on page 1
        let back_button = document.createElement('div');
        back_button.classList.add('step_button');
        back_button.classList.add('back_button');
        back_button.addEventListener('click', ()=>{
            container.step_page_footer.querySelector('.gform_previous_button').click();
        });
        container.step_page_footer.prepend(back_button);
    }
}

function progress_bar(container, current_page){

    if( !show_progress_count(container) ) return;

    let progress_indicator = document.createElement('div');
    progress_indicator.innerHTML = '<span class="sep">/</span>';
    progress_indicator.classList.add('progress_indicator');
    container.step_page_footer.prepend(progress_indicator);

    let total = document.createElement('div');
    total.classList.add('total');
    total.innerText = container.total_pages;
    progress_indicator.append(total);

    let current = document.createElement('div');
    current.classList.add('current');
    current.innerText = current_page;
    progress_indicator.prepend(current);
}

function show_back_button(container){

    if(
        !container.ff_form_steps_con.dataset.show_back_button
        || container.ff_form_steps_con.dataset.show_back_button == 'false'
    ) {
        return false;
    }

    return true;
}

function show_progress_count(container){
    
    if(
        !container.ff_form_steps_con.dataset.show_progress_count
        || container.ff_form_steps_con.dataset.show_progress_count == 'false'
    ) {
        return false;
    }

    return true;
}

jQuery(document).on('gform_post_render', function(event, form_id, current_page){
    form_steps(form_id, current_page);
});